import React from "react";

import MatchTileScoreboard from "@/game-fortnite/components/MatchDataTablePostmatch.jsx";

export default function MatchTileScoreboardMini(props) {
  return (
    <div
      className="overflow-hidden"
      style={{ height: "215px", overflowY: "auto" }}
    >
      {React.createElement(MatchTileScoreboard, props)}
    </div>
  );
}
